.nav-item {
    font-size: 16px;
}

html {
    font-size: 12px;
}

/* Should inject semantic-u Responsive.onlyComputer.minWidth, but how? */
@media (max-width: 375px) {
    html {
        font-size: 18px;
    }
}

.ui.modal > .actions {
    text-align: left;
}

/* https://github.com/fomantic/Fomantic-UI/pull/584 */
.ui.fluid[class*="left labeled"].icon.button {
  padding-left: 4.07142857em !important;
  padding-right: 1.5em !important;
}

.ui.fluid[class*="right labeled"].icon.button {
  padding-left: 1.5em !important;
  padding-right: 4.07142857em !important;
}
