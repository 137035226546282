#content {
    width: 100%;
}
.react-grid-layout {
    border: 1px solid #ccc;
}
.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}
.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
.react-grid-item {
    cursor: move;
    box-sizing: border-box;
    overflow: hidden;
}

.react-grid-item.resizing {
    opacity: 0.9;
}
.react-grid-item.static {
    background: #cce;
}
.react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
}
.react-grid-item .minMax {
    font-size: 12px;
}
.react-grid-item .add {
    cursor: pointer;
}
.react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
li b {
    font-size: 19px;
    line-height: 14px;
}
.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
}
.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}
.react-grid-item.cssTransforms {
    transition-property: transform;
}
.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}
