@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600|Sanchez&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600|Sanchez&display=swap);
.c-green {
    color: #6af476;
}

.blue-bg {
    background-color: #17104F;
}

.green-bg {
    background-color: #6af476;
}

.c-white {
    color: #fff;
}

.font-monserrat {
    font-family: 'Montserrat', sans-serif;
}

.font-sanchez {
    font-family: 'Sanchez', serif;
}

#logo .closed .vertical {
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

#logo .closed .horizontal {
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    opacity: 1;
}

#logo .opened {
    opacity: 1;
}

#logo .opened .vertical {
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

#logo .opened .horizontal {
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0;
}

#logo .logo-plus {
    height: 53px;
    width: 54px;
    font-size: 1em;
    display: flex;
}

#logo .logo-plus .logo {
    position: relative;
    width: 54px;
    height: 53px;
    background-color: #6af476;
}

#logo .logo-plus .logo:hover {
    background-color: #5cd667;
    cursor: pointer;
}

#logo .logo-plus .logo .horizontal {
    position: absolute;
    background-color: #17104F;
    width: 16px;
    height: 4px;
    left: 50%;
    margin-left: -7.5px;
    top: 50%;
    margin-top: -2.5px;
}

#logo .logo-plus .logo .vertical {
    position: absolute;
    background-color: #17104F;
    width: 4px;
    height: 16px;
    left: 50%;
    margin-left: -1.5px;
    top: 50%;
    margin-top: -8px;
}

#logo {
    display: flex;
    flex-wrap: wrap;
    max-height: 53px;
}

#logo .logotext {
    display: flex;
    background: #170f4f;
    padding: 1rem 1.5rem;
    font-size: 1.3rem;
     line-height: 2rem;
    max-height: 53px;
}

#logo .logotext .font-monserrat{
    font-weight: 600;
}

#logo .logotext span:first-child {
    margin-right: .4rem;
}

.knop {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-weight: 600;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-position: center right 2rem;
    background-repeat: no-repeat;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 5rem;
    transition: background-position .15s ease-in-out, box-shadow .25s cubic-bezier(0, 0, 0.2, 1);
    border: 0;
    margin: 0 0.5rem 0.5rem 0;
}

.knop:focus {
  outline: 0;
}

.knop-primary {
    background-color: #17104F;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath fill='%236AF476' fill-rule='nonzero' d='M9.886 0l-.808.81 4.716 4.628H0v1.124h13.794L9.078 11.19l.808.81L16 6z'/%3E%3C/svg%3E");
    color: #6af476;
}

.knop-primary-opp {
    background-color: #6af476;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath fill='%2317104F' fill-rule='nonzero' d='M9.886 0l-.808.81 4.716 4.628H0v1.124h13.794L9.078 11.19l.808.81L16 6z'/%3E%3C/svg%3E");
    color: #17104F;
}

.knop-lg {
    padding: 1rem 4rem 1rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
}

.knop-sm {
    background-image: none;
    padding: .6rem 1.1rem .6rem 1.1rem;
    font-size: .8rem;
    line-height: 1;
}

.txt-link {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-weight: 600;
    font-size: 1rem;
    color: #ffffff;
    padding-right: 10rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath fill='white' fill-rule='nonzero' d='M9.886 0l-.808.81 4.716 4.628H0v1.124h13.794L9.078 11.19l.808.81L16 6z'/%3E%3C/svg%3E");
    background-position: center right .5rem;
    background-repeat: no-repeat;
    transition: background-position .15s ease-in-out, box-shadow .25s cubic-bezier(0, 0, 0.2, 1);
}

.txt-link:hover {
    background-position: center right 0rem;
    color: #ffffff;
    text-decoration: underline;
}

.txt-link:active {
    background-position: center right 0rem;
    color: #ffffff;
    text-decoration: underline;
}

.txt-link-logo {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-weight: 600;
    font-size: 1.4rem;
    color: #ffffff;
    padding-right: 3rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath fill='white' fill-rule='nonzero' d='M9.886 0l-.808.81 4.716 4.628H0v1.124h13.794L9.078 11.19l.808.81L16 6z'/%3E%3C/svg%3E");
    background-position: center right .5rem;
    background-repeat: no-repeat;
    transition: background-position .15s ease-in-out, box-shadow .25s cubic-bezier(0, 0, 0.2, 1);
}

.txt-link-logo:hover {
    background-position: center right 0rem;
    color: #ffffff;
}

.txt-link-logo:active {
    background-position: center right 0rem;
    color: #ffffff;
}


.knop:focus {
    outline: 0;
}


.knop-primary:hover {
    box-shadow: 0 0 0 0.2rem #8aff95;
    background-position: center right 1rem;
}

.knop-primary:active {
    background-color: #0d082b;
    box-shadow: 0 0 0 0.2rem #6af476;
    background-position: center right 1rem;
}

.knop-primary-opp:hover {
    box-shadow: 0 0 0 0.2rem rgba(23, 16, 79, 0.5);
    background-position: center right 1rem;
}

.knop-primary-opp:active {
    background-color: #61da6b;
    box-shadow: 0 0 0 0.2rem rgba(23, 16, 79, 1);
    background-position: center right 1rem;
}

#menu {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    background: #180f4f;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
}

#menu .menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0 .5rem;
    align-items: center;
    justify-content: center;
}

#menu .menu-right {
    margin: 0 0 0 auto;
}

#menu .menu li {
    font-weight: 600;
    padding: 0 1rem;
}

#menu .menu li .menu-item {
    color: #fff;
    text-decoration: none;
}

#menu .menu li.profiel-item {
    background-image: url("data:image/svg+xml,%3Csvg width='12px' height='7px' viewBox='0 0 12 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path' fill='%23C5C3D3' fill-rule='nonzero' transform='translate(6.000000, 3.461052) rotate(90.000000) translate(-6.000000, -3.461052) ' points='3.3473685 -2.5389475 2.5389475 -1.7290301 7.2547365 2.899069 7.8581475 3.4610525 7.2547365 4.023036 2.5389475 8.6511351 3.3473685 9.4610525 9.4610525 3.4610525'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
    padding: 0 1.1rem 0 0;
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
  line-height: 2rem;
  margin-left: .5rem;
}

#menu .menu li .profiel {
    margin-right: .5rem;
    float:left;
}

#menu .menu li .menu-item:hover {
    text-decoration: underline;
}

@-webkit-keyframes menu-animation {
    from {
        opacity: 0;
        -webkit-transform: rotatex(-90deg);
                transform: rotatex(-90deg);
    }

    to {
        opacity: 1;
        -webkit-transform: rotatex(0deg);
                transform: rotatex(0deg);
    }
}

@keyframes menu-animation {
    from {
        opacity: 0;
        -webkit-transform: rotatex(-90deg);
                transform: rotatex(-90deg);
    }

    to {
        opacity: 1;
        -webkit-transform: rotatex(0deg);
                transform: rotatex(0deg);
    }
}

#menu .menu-item {
    -webkit-perspective: 800px;
            perspective: 800px;

}

#menu .sub-menu {
    position: absolute;
    width: 100%;
    max-width: 270px;
    right: calc(10px + 2em);
    display: none;
    padding-top: 16px;
    -webkit-animation-name: menu-animation;
            animation-name: menu-animation;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}

#menu .sub-menu-in {
    color: #000;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #17104f29;
    border-radius: 4px;
    padding: 1em;
}

#menu .sub-menu-in span.name {
    color: #000000;
    letter-spacing: 0.46px;
    font-size: 1rem;
    display: block;
    width: 100%;
    margin-top: 1rem;
}

#menu .sub-menu-in span.email {
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: #9895B1;
    letter-spacing: 0.34px;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    font-weight: 300;
}

#menu .sub-menu-in span.company {
    font-size: 0.8rem;
    line-height: 0.8rem;
    width: 100%;
    display: block;
    font-weight: 600;
}

#menu .sub-menu-in span.company-type {
    font-size: 0.8rem;
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    font-weight: 300;
    color: #9895B1;
}

#menu .menu li:hover>.sub-menu,#menu .dropdown-item.active .sub-menu  {
    display: block;
}

#menu {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    background: #180f4f !important;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    z-index: 10000;
    font-size: 15px;

}

#menu .menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0 .5rem;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}

#menu .menu-right {
    margin: 0 0 0 auto;
}

#menu .menu li {
    font-weight: 600;
    padding: 0 1rem;
}

#menu .menu li.has-dropdown {
    position: relative;
}

#menu .menu li.has-dropdown:hover .dropdown-menu {
    display: block;
}

#menu .menu li .menu-item {
    color: #fff;
    text-decoration: none;
}

#menu .menu li.profiel-item, #menu .menu li.has-dropdown {
    background-image: url("data:image/svg+xml,%3Csvg width='12px' height='7px' viewBox='0 0 12 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path' fill='%23C5C3D3' fill-rule='nonzero' transform='translate(6.000000, 3.461052) rotate(90.000000) translate(-6.000000, -3.461052) ' points='3.3473685 -2.5389475 2.5389475 -1.7290301 7.2547365 2.899069 7.8581475 3.4610525 7.2547365 4.023036 2.5389475 8.6511351 3.3473685 9.4610525 9.4610525 3.4610525'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
    padding: 0 1.1rem 0 0;
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
    line-height: 2rem;
    margin-left: .5rem;
}

#menu .menu li .profiel {
    margin-right: .5rem;
    float: left;
}

#menu .menu li .menu-item:hover {
    text-decoration: underline;
}

@-webkit-keyframes menu-animation {
    from {
        opacity: 0;
        -webkit-transform: rotatex(-90deg);
                transform: rotatex(-90deg);
    }

    to {
        opacity: 1;
        -webkit-transform: rotatex(0deg);
                transform: rotatex(0deg);
    }
}

@keyframes menu-animation {
    from {
        opacity: 0;
        -webkit-transform: rotatex(-90deg);
                transform: rotatex(-90deg);
    }

    to {
        opacity: 1;
        -webkit-transform: rotatex(0deg);
                transform: rotatex(0deg);
    }
}

#menu .menu-item {
    -webkit-perspective: 800px;
            perspective: 800px;
    margin-left: 17px;
    margin-right: 3px;

}


#menu .sub-menu {
    position: absolute;
    width: 100%;
    max-width: 270px;
    right: calc(10px + 2em);
    display: none;
    padding-top: 16px;
    -webkit-animation-name: menu-animation;
            animation-name: menu-animation;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}

#menu .sub-menu-in {
    color: #000;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #17104f29;
    border-radius: 4px;
    padding: 1em;
}

#menu .sub-menu-in span.name {
    color: #000000;
    letter-spacing: 0.46px;
    font-size: 1rem;
    display: block;
    width: 100%;
    margin-top: 1rem;
}

#menu .sub-menu-in span.email {
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: #9895B1;
    letter-spacing: 0.34px;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    font-weight: 300;
}

#menu .sub-menu-in span.company {
    font-size: 0.8rem;
    line-height: 0.8rem;
    width: 100%;
    display: block;
    font-weight: 600;
}

#menu .sub-menu-in span.company-type {
    font-size: 0.8rem;
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    font-weight: 300;
    color: #9895B1;
}

#menu .menu li:hover > .sub-menu, #menu .dropdown-item.active .sub-menu {
    display: block;
}

#menu .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    text-align: left;
    list-style: none;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #17104f29;
    border-radius: 4px;
    padding: 1em;
    min-width: 250px;
    -webkit-animation-name: menu-animation;
            animation-name: menu-animation;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    margin-top: 0px;
}

#menu .dropdown-menu li {
    padding: .5em 0;
}

#menu .dropdown-menu li a.menu-item {
    color: #000;
}

/****** LOGO ******/

.c-green {
    color: #6af476;
}

.blue-bg {
    background-color: #17104F !important;
}

.green-bg {
    background-color: #6af476;
}

.c-white {
    color: #fff;
}

.font-monserrat {
    font-family: 'Montserrat', sans-serif;
}

.font-sanchez {
    font-family: 'Sanchez', serif;
}

#logo .closed .vertical {
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

#logo .closed .horizontal {
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    opacity: 1;
}

#logo .opened {
    opacity: 1;
    z-index: 10000;

}

#logo .opened .vertical {
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

#logo .opened .horizontal {
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0;
}

#logo .logo-plus {
    height: 4em;
    width: 4em;
    font-size: 1em;
    display: flex;
}

#logo .logo-plus .logo {
    position: relative;
    width: 53px;
    height: 53px;
    background-color: #6af476;
}

#logo .logo-plus .logo:hover {
    background-color: #5cd667;
    cursor: pointer;
}

#logo .logo-plus .logo .horizontal {
    position: absolute;
    background-color: #17104F;
    width: 16px;
    height: 4px;
    left: 50%;
    margin-left: -7.5px;
    top: 50%;
    margin-top: -2.5px;
}

#logo .logo-plus .logo .vertical {
    position: absolute;
    background-color: #17104F;
    width: 4px;
    height: 16px;
    left: 50%;
    margin-left: -1.5px;
    top: 50%;
    margin-top: -8px;
}

#logo {
    display: flex;
    flex-wrap: wrap;
    z-index: 10000;

}

#logo .logotext {
    display: flex;
    background: #170f4f;
    padding: 1rem 1.5rem;
    font-size: 1.3rem;
    line-height: 2rem;
}

#logo .logotext span:first-child {
    margin-right: .4rem;
}

/****** @button ******/

.knop {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-weight: 600;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-position: center right 2rem;
    background-repeat: no-repeat;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 5rem;
    transition: background-position .15s ease-in-out, box-shadow .25s cubic-bezier(0, 0, 0.2, 1);
    border: 0;
    marign-bottom: 1rem;
}

.knop:focus {
    outline: 0;
}

.knop-primary {
    background-color: #17104F;
    color: #6AF476;
}

.knop-primary-opp {
    background-color: #6af476;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath fill='%2317104F' fill-rule='nonzero' d='M9.886 0l-.808.81 4.716 4.628H0v1.124h13.794L9.078 11.19l.808.81L16 6z'/%3E%3C/svg%3E");
    color: #17104F;
}

.knop-sm {
    padding: .6rem 1.1rem .6rem 1.1rem;
    font-size: .8rem;
    line-height: 1;
}

.knop-sm {
    background-image: none;
    padding: .6rem 1.1rem .6rem 1.1rem;
    font-size: .8rem;
    line-height: 1;
}

.knop-primary:hover {
    box-shadow: 0 0 0 0.2rem #6af476;
    background-position: center right 1rem;
}

.knop-primary:active {
    background-color: #0d082b;
    box-shadow: 0 0 0 0.2rem #6af476;
    background-position: center right 1rem;
}

.knop-primary-opp:hover {
    box-shadow: 0 0 0 0.2rem #0d082b;
    background-position: center right 1rem;
}

.knop-primary-opp:active {
    background-color: #61da6b;
    box-shadow: 0 0 0 0.2rem #0d082b;
    background-position: center right 1rem;
}

#headertop {
    padding: 2em;
    color: #fff;
    background-color: #17104F;
}

#headertop h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1;
    font-size: 3rem;
    margin: 1.5rem 0 0 0;
}

#headertop h1 sub {
    font-family: 'Sanchez', serif;
    font-size: 45%;
    font-weight: 300;
    color: #6af476;
    display: block;
    margin-top: 5px;
    line-height: 1;
}

.breadcrumbs {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 1rem 0;
    padding: 0;
    list-style: none;
}

.breadcrumbs li {
    display: flex;
    position: relative;
    margin: 0 1rem 0 0;
}

.breadcrumbs li:after {
    content: "";
    position: absolute;
    right: -11px;
    background-image: url("data:image/svg+xml,%3Csvg width='6px' height='9px' viewBox='0 0 6 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M4.24264069,7.24264069 L3.24264069,7.24264069 L3.24264069,2.24264069 L-1.75735931,2.24264069 L-1.75735931,1.24264069 L4.24264069,1.24264069 L4.24264069,7.24264069 Z' id='arrow' fill='%23BAC3D5' transform='translate(1.242641, 4.242641) rotate(-315.000000) translate(-1.242641, -4.242641)'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    width: 6px;
    width: 6px;
    top: 6px;
    bottom: 0;
    height: 9px;
}

.breadcrumbs li:last-child:after {
    display: none;
}

.breadcrumbs li a {
    font-weight: 500;
    color: #C5C3D3;
    line-height: 1.2rem;
    font-size: .7rem;
    text-decoration: none;
}

.breadcrumbs li a:hover {
    text-decoration: underline;
}



/*******************************
     User Global Variables
*******************************/
.daycy.calendar > .grid > .row > .cell.range {
  background-color: #fbfdfd;
}
.daycy.calendar > .grid > .row > .cell.week {
  color: #8dd2ca;
}
.daycy.calendar > .grid > .row > .cell.week.label {
  color: rgba(34, 36, 38, 0.5);
}
.daycy.calendar > .grid > .row > .cell.selected {
  background-color: #73b9b1;
}

.nav-item {
    font-size: 16px;
}

html {
    font-size: 14px;
}

/* Make none fullscreen modal have the close icon inside the modal instead of
 * outside.
 */
.ui.modal > .close {
    top: 0.8rem !important;
    right: 1rem !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

a:hover {
    color: unset;
    text-decoration: unset;
}

/* toolbar component */
section {
    background-color: #EEEEEE !important;
}
i.icon.voicemail:after { content: '\F897'; }

@font-face {
    font-family: "cy-custom-icons";
    src: url(/static/sms/static/media/cy-custom-icons.55aa477b.eot?#iefix) format("embedded-opentype"),
url(/static/sms/static/media/cy-custom-icons.146923cb.woff2) format("woff2"),
url(/static/sms/static/media/cy-custom-icons.63a89fc9.woff) format("woff"),
url(/static/sms/static/media/cy-custom-icons.47891432.ttf) format("truetype"),
url(/static/sms/static/media/cy-custom-icons.751bd1ab.svg#cy-custom-icons) format("svg");
}

i.icon.cy {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.cy:before {
    content: "\f101";
}

.nav-item {
    font-size: 16px;
}

html {
    font-size: 12px;
}

/* Should inject semantic-u Responsive.onlyComputer.minWidth, but how? */
@media (max-width: 375px) {
    html {
        font-size: 18px;
    }
}

.ui.modal > .actions {
    text-align: left;
}

/* https://github.com/fomantic/Fomantic-UI/pull/584 */
.ui.fluid[class*="left labeled"].icon.button {
  padding-left: 4.07142857em !important;
  padding-right: 1.5em !important;
}

.ui.fluid[class*="right labeled"].icon.button {
  padding-left: 1.5em !important;
  padding-right: 4.07142857em !important;
}

#content {
    width: 100%;
}
.react-grid-layout {
    border: 1px solid #ccc;
}
.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}
.columns {
    -webkit-columns: 120px;
    columns: 120px;
}
.react-grid-item {
    cursor: move;
    box-sizing: border-box;
    overflow: hidden;
}

.react-grid-item.resizing {
    opacity: 0.9;
}
.react-grid-item.static {
    background: #cce;
}
.react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
}
.react-grid-item .minMax {
    font-size: 12px;
}
.react-grid-item .add {
    cursor: pointer;
}
.react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -webkit-grab;
}
li b {
    font-size: 19px;
    line-height: 14px;
}
.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
}
.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
}
.react-grid-item.cssTransforms {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}
.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

