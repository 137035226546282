@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600|Sanchez&display=swap');

.c-green {
    color: #6af476;
}

.blue-bg {
    background-color: #17104F;
}

.green-bg {
    background-color: #6af476;
}

.c-white {
    color: #fff;
}

.font-monserrat {
    font-family: 'Montserrat', sans-serif;
}

.font-sanchez {
    font-family: 'Sanchez', serif;
}

#logo .closed .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
}

#logo .closed .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
}

#logo .opened {
    opacity: 1;
}

#logo .opened .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
}

#logo .opened .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
}

#logo .logo-plus {
    height: 53px;
    width: 54px;
    font-size: 1em;
    display: flex;
}

#logo .logo-plus .logo {
    position: relative;
    width: 54px;
    height: 53px;
    background-color: #6af476;
}

#logo .logo-plus .logo:hover {
    background-color: #5cd667;
    cursor: pointer;
}

#logo .logo-plus .logo .horizontal {
    position: absolute;
    background-color: #17104F;
    width: 16px;
    height: 4px;
    left: 50%;
    margin-left: -7.5px;
    top: 50%;
    margin-top: -2.5px;
}

#logo .logo-plus .logo .vertical {
    position: absolute;
    background-color: #17104F;
    width: 4px;
    height: 16px;
    left: 50%;
    margin-left: -1.5px;
    top: 50%;
    margin-top: -8px;
}

#logo {
    display: flex;
    flex-wrap: wrap;
    max-height: 53px;
}

#logo .logotext {
    display: flex;
    background: #170f4f;
    padding: 1rem 1.5rem;
    font-size: 1.3rem;
     line-height: 2rem;
    max-height: 53px;
}

#logo .logotext .font-monserrat{
    font-weight: 600;
}

#logo .logotext span:first-child {
    margin-right: .4rem;
}

.knop {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-weight: 600;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-position: center right 2rem;
    background-repeat: no-repeat;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 5rem;
    transition: background-position .15s ease-in-out, box-shadow .25s cubic-bezier(0, 0, 0.2, 1);
    border: 0;
    margin: 0 0.5rem 0.5rem 0;
}

.knop:focus {
  outline: 0;
}

.knop-primary {
    background-color: #17104F;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath fill='%236AF476' fill-rule='nonzero' d='M9.886 0l-.808.81 4.716 4.628H0v1.124h13.794L9.078 11.19l.808.81L16 6z'/%3E%3C/svg%3E");
    color: #6af476;
}

.knop-primary-opp {
    background-color: #6af476;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath fill='%2317104F' fill-rule='nonzero' d='M9.886 0l-.808.81 4.716 4.628H0v1.124h13.794L9.078 11.19l.808.81L16 6z'/%3E%3C/svg%3E");
    color: #17104F;
}

.knop-lg {
    padding: 1rem 4rem 1rem 2rem;
    font-size: 1rem;
    line-height: 1.5;
}

.knop-sm {
    background-image: none;
    padding: .6rem 1.1rem .6rem 1.1rem;
    font-size: .8rem;
    line-height: 1;
}

.txt-link {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-weight: 600;
    font-size: 1rem;
    color: #ffffff;
    padding-right: 10rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath fill='white' fill-rule='nonzero' d='M9.886 0l-.808.81 4.716 4.628H0v1.124h13.794L9.078 11.19l.808.81L16 6z'/%3E%3C/svg%3E");
    background-position: center right .5rem;
    background-repeat: no-repeat;
    transition: background-position .15s ease-in-out, box-shadow .25s cubic-bezier(0, 0, 0.2, 1);
}

.txt-link:hover {
    background-position: center right 0rem;
    color: #ffffff;
    text-decoration: underline;
}

.txt-link:active {
    background-position: center right 0rem;
    color: #ffffff;
    text-decoration: underline;
}

.txt-link-logo {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    font-weight: 600;
    font-size: 1.4rem;
    color: #ffffff;
    padding-right: 3rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12'%3E%3Cpath fill='white' fill-rule='nonzero' d='M9.886 0l-.808.81 4.716 4.628H0v1.124h13.794L9.078 11.19l.808.81L16 6z'/%3E%3C/svg%3E");
    background-position: center right .5rem;
    background-repeat: no-repeat;
    transition: background-position .15s ease-in-out, box-shadow .25s cubic-bezier(0, 0, 0.2, 1);
}

.txt-link-logo:hover {
    background-position: center right 0rem;
    color: #ffffff;
}

.txt-link-logo:active {
    background-position: center right 0rem;
    color: #ffffff;
}


.knop:focus {
    outline: 0;
}


.knop-primary:hover {
    box-shadow: 0 0 0 0.2rem #8aff95;
    background-position: center right 1rem;
}

.knop-primary:active {
    background-color: #0d082b;
    box-shadow: 0 0 0 0.2rem #6af476;
    background-position: center right 1rem;
}

.knop-primary-opp:hover {
    box-shadow: 0 0 0 0.2rem rgba(23, 16, 79, 0.5);
    background-position: center right 1rem;
}

.knop-primary-opp:active {
    background-color: #61da6b;
    box-shadow: 0 0 0 0.2rem rgba(23, 16, 79, 1);
    background-position: center right 1rem;
}

#menu {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    background: #180f4f;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
}

#menu .menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0 .5rem;
    align-items: center;
    justify-content: center;
}

#menu .menu-right {
    margin: 0 0 0 auto;
}

#menu .menu li {
    font-weight: 600;
    padding: 0 1rem;
}

#menu .menu li .menu-item {
    color: #fff;
    text-decoration: none;
}

#menu .menu li.profiel-item {
    background-image: url("data:image/svg+xml,%3Csvg width='12px' height='7px' viewBox='0 0 12 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path' fill='%23C5C3D3' fill-rule='nonzero' transform='translate(6.000000, 3.461052) rotate(90.000000) translate(-6.000000, -3.461052) ' points='3.3473685 -2.5389475 2.5389475 -1.7290301 7.2547365 2.899069 7.8581475 3.4610525 7.2547365 4.023036 2.5389475 8.6511351 3.3473685 9.4610525 9.4610525 3.4610525'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
    padding: 0 1.1rem 0 0;
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
  line-height: 2rem;
  margin-left: .5rem;
}

#menu .menu li .profiel {
    margin-right: .5rem;
    float:left;
}

#menu .menu li .menu-item:hover {
    text-decoration: underline;
}

@keyframes menu-animation {
    from {
        opacity: 0;
        transform: rotatex(-90deg);
    }

    to {
        opacity: 1;
        transform: rotatex(0deg);
    }
}

#menu .menu-item {
    perspective: 800px;

}

#menu .sub-menu {
    position: absolute;
    width: 100%;
    max-width: 270px;
    right: calc(10px + 2em);
    display: none;
    padding-top: 16px;
    animation-name: menu-animation;
    transform-origin: top;
    animation-duration: 0.5s;
}

#menu .sub-menu-in {
    color: #000;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #17104f29;
    border-radius: 4px;
    padding: 1em;
}

#menu .sub-menu-in span.name {
    color: #000000;
    letter-spacing: 0.46px;
    font-size: 1rem;
    display: block;
    width: 100%;
    margin-top: 1rem;
}

#menu .sub-menu-in span.email {
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: #9895B1;
    letter-spacing: 0.34px;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    font-weight: 300;
}

#menu .sub-menu-in span.company {
    font-size: 0.8rem;
    line-height: 0.8rem;
    width: 100%;
    display: block;
    font-weight: 600;
}

#menu .sub-menu-in span.company-type {
    font-size: 0.8rem;
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    font-weight: 300;
    color: #9895B1;
}

#menu .menu li:hover>.sub-menu,#menu .dropdown-item.active .sub-menu  {
    display: block;
}
